.menu-notification-list-container {
  @apply absolute top-full mt-4 -right-24 md:right-0 w-full min-w-80 p-4 text-gray-900 bg-white rounded-lg shadow;
}

.menu-mark-all-read-btn {
  @apply text-sm font-lato-medium text-blue-azure-radiance-50 focus:outline-none bg-transparent hover:text-blue-800;
}

.menu-view-all-btn {
  @apply px-3 w-full py-2 text-sm font-lato-medium text-center text-white bg-blue-azure-radiance-50 rounded-sm hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300;
}
