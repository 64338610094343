.switch {
  width: 52px;
  height: 28px;
  position: relative;
  display: inline-block;
}

.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}

.slider {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  transition: 0.4s;
  position: absolute;
  background-color: #ccc;
  -webkit-transition: 0.2s;
}

.slider:before {
  left: 4px;
  bottom: 4px;
  content: '';
  width: 20px;
  height: 20px;
  transition: 0.4s;
  position: absolute;
  -webkit-transition: 0.4s;
  background-color: white;
}

input:checked + .slider {
  background-color: #4589ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4589ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-field {
  @apply flex items-center justify-between mb-3;
}
