.auth-layout-container {
  @apply min-w-full h-screen bg-blue-ice flex;
}

.img-container {
  width: 47%;
}

.copper-img {
  @apply h-full w-full;
}

.right-container {
  @apply flex flex-col justify-center items-center flex-grow self-center;
}

.copper-logo {
  @apply w-40 mb-6;
}

.outlet-container {
  @apply h-3/4 w-11/12 md:w-4/6;
}
