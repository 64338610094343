.edit-ticket-container {
  @apply lg:flex justify-between w-full md:px-10 py-3 text-black-slate;
}

.ticket-details-container {
  @apply w-full lg:w-[28%] bg-white px-8 md:pr-[20%] lg:pr-8 py-5 rounded-md;
}

.issue-details-container {
  @apply w-full lg:w-[71%] bg-white p-9 rounded-md mt-5 lg:mt-0;
}

.avatar-container {
  @apply flex items-center gap-4 mb-6;
}

.avatar {
  @apply rounded-full text-white font-lato-bold flex justify-center items-center;
}

.assignee-avatar {
  @apply avatar w-12 h-12 bg-blue-sky;
}

.customer-avatar {
  @apply avatar w-16 h-16 bg-red-coral-100 text-xl;
}

.name-email-container {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-x-7 my-4;
}

.attachments {
  @apply flex flex-wrap gap-2 mt-2 text-xs font-lato-semibold text-blue-stone;
  li {
    @apply bg-blue-mild p-1 rounded-md flex items-center;
  }
}

.button-container {
  @apply flex justify-center sm:justify-end gap-4 mt-5;
}
