@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'lato-light';
    src: url('../assets/fonts/Lato-Light.ttf') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'lato-regular';
    src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'lato-medium';
    src: url('../assets/fonts/Lato-Medium.ttf') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'lato-semibold';
    src: url('../assets/fonts/Lato-Semibold.ttf') format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'lato-bold';
    src: url('../assets/fonts/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
  }

  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
  h5 {
    @apply text-sm;
  }
  h6 {
    @apply text-xs;
  }
  label {
    font-size: calc(0.75rem + 0.3vw);
  }
  small,
  p {
    @apply text-sm;
  }
  body {
    font-family: 'lato-regular', system-ui, sans-serif;
    @apply bg-gray-zircon;
  }
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #a9a9a9;
}

.input-group-wrapper {
  @apply flex flex-col;
}

.input-label {
  @apply text-sm;
}

.input-show-password-btn {
  @apply pr-3 cursor-pointer outline-none bg-white;
}
