.new-ticket-input {
  @apply appearance-none w-full border-2 rounded text-black leading-tight text-xs font-lato-regular;
}

.file-input {
  @apply block w-full text-xs text-gray-rolling-stone
  file:mr-4 file:py-2 file:px-4
  file:rounded-[4px] file:border-0
  file:text-xs file:font-lato-medium
  file:bg-blue-azure-radiance-100 file:text-white
  hover:file:bg-blue-jordy;
}
