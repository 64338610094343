.comment-list-container {
  @apply border-2 p-3 mt-2 rounded-md max-h-44 overflow-scroll;
}

.comment {
  @apply text-xs font-lato-bold mb-1 p-2 bg-gray-100 rounded-md w-fit flex flex-col min-w-[100%] md:min-w-[55%];
}

.comment-input {
  @apply rounded-b-md rounded-t-none text-xs p-2 h-24 resize-none;
}

.comment-button {
  @apply absolute bottom-0 right-0 mb-3 mr-3 disabled:opacity-60 disabled:cursor-not-allowed;
}
