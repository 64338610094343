.pagination-container {
  @apply flex flex-wrap text-xs md:text-sm;
}

.pagination-break-link {
  @apply flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700;
}

.pagination-previous-link {
  @apply flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white rounded-s-md hover:bg-gray-100 hover:text-gray-700;
}

.pagination-next-link {
  @apply flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white rounded-e-md hover:bg-gray-100 hover:text-gray-700;
}

.pagination-page-link {
  @apply flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700;
}

.pagination-active-link {
  @apply !text-blue-600 !bg-blue-50 hover:!bg-blue-100 hover:!text-blue-700;
}

.pagination-disabled-link {
  @apply !cursor-not-allowed text-gray-400 select-none;
}

.pagination-info-container {
  @apply flex flex-col items-center md:flex-row md:flex-wrap md:justify-center gap-4 w-full;
}

.per-page-select {
  @apply min-w-14 p-1 border-2 border-gray-400 rounded-md text-xs md:text-sm focus:outline-none focus:border-blue-royal-azure;
}
