.sidebar-container {
  @apply fixed top-0 bottom-0 left-0 z-50 sm:z-40 bg-gray-zircon w-44 pt-11 px-2 sm:px-0 sm:pt-20 scrollbar-thin scrollbar-thumb-slate-200 overflow-y-auto sm:w-24;
}

.sidebar-close-btn {
  @apply text-gray-mine-shaft bg-transparent hover:bg-white-link-water-200 rounded-md text-sm p-1.5 sm:hidden absolute top-2.5 end-2.5 inline-flex items-center;
}

.sidebar-item-link {
  @apply flex items-center text-gray-mine-shaft sm:flex-col sm:gap-3;
}

.sidebar-item-img {
  @apply w-[26px] h-4 text-gray-mine-shaft transition duration-75;
}

.sidebar-item-btn {
  @apply flex items-center w-full text-gray-mine-shaft sm:flex-col sm:gap-3;
}

.sidebar-subitem-link {
  @apply flex items-center text-10 w-full p-2 text-gray-mine-shaft pl-[60px] sm:px-2 sm:py-2 sm:justify-center hover:bg-blue-hawkes transition duration-75;
}

.sidebar-item-link:hover div {
  @apply bg-white-link-water-200;
}
