.editor-preview ul {
  list-style-type: disc;
  margin: 16px 0;
  padding-left: 10px;
  list-style-position: inside;
}

.editor-preview ol {
  list-style-type: decimal;
  list-style-position: inside;
  margin: 16px 0;
  padding-left: 10px;
}

.editor-preview blockquote {
  border-left: 4px solid #d0d0d0;
  padding-left: 10px;
  margin-left: 0;
  color: #6a737d;
}

.editor-preview p {
  margin: 16px 0;
}

.editor-preview br {
  display: block;
  content: '';
  margin: 0.5em 0;
}

.editor-preview h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 24px 0;
}
