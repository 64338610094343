.nav-container {
  @apply fixed top-0 bg-gray-zircon z-50 w-full py-8 px-5 flex h-11 justify-between items-center;
}

.nav-menu-btn {
  @apply inline-flex items-center text-sm text-gray-charcoal rounded-[4px] sm:hidden;
}

.nav-avatar-container {
  line-height: normal;
  @apply flex items-center justify-center w-8 h-8 bg-red-magenta rounded-full mr-2;
}

.nav-notification-btn {
  @apply inline-flex justify-center items-center h-8 w-8 text-sm bg-transparent font-lato-semibold rounded-md text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none;
}

.nav-dropdown-item {
  @apply hover:bg-white-link-water-50 whitespace-nowrap py-0.5 flex items-center pl-3 cursor-pointer;
}
