.login-card {
  @apply w-full bg-white rounded-2xl p-6 sm:p-11 shadow-card-2xl text-gray-abbey-road;
}
.login-header {
  @apply text-center font-lato-bold text-black;
}
.login-input {
  @apply shadow w-full appearance-none border rounded text-gray-700 leading-tight;
}
.login-button {
  @apply flex justify-center w-full items-center p-3.5 rounded-2xl mt-14 mb-5;
}
.login-button-text {
  @apply text-white-alabaster-100 text-center leading-[normal] font-lato-bold text-xl;
}
.login-forgot {
  @apply cursor-pointer font-lato-bold hover:underline text-sm hover:text-blue-azure-radiance-50;
}
