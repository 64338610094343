.ag-header-viewport {
  background-color: #e9f0f7;
}

.ag-center-cols-container {
  min-width: 100% !important;
}

.ag-header-cell-label {
  justify-content: left;
}

.ag-cell {
  display: flex !important;
  justify-content: left !important;
}

.ag-blue-rows {
  @apply bg-blue-50 !important;
}

.icon-style {
  @apply pr-8 h-full;
}

.input-wrapper {
  @apply grid grid-cols-1 md:grid-cols-2 gap-x-24 md:mt-4;
}

.form-button {
  @apply rounded-[4px] text-xs p-2 text-center min-w-24 font-lato-semibold;
}

.submit-button {
  @apply form-button !bg-blue-azure-radiance-100 hover:!bg-blue-azure-radiance-50 text-white;
}

.cancel-button {
  @apply form-button border border-gray-silver-chalice-50 hover:bg-gray-100 bg-transparent text-black-slate;
}
