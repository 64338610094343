.profile-container {
  @apply w-full p-4 md:p-6 lg:p-10 flex flex-col md:flex-row md:gap-14 lg:gap-20;
}

.profile-card {
  @apply bg-blue-zodiac w-full md:w-[70%] lg:w-[40%] rounded-xl flex flex-col items-center justify-center p-6 md:p-10 gap-5 text-white md:self-center lg:self-auto;
}

.profile-avatar {
  @apply rounded-full h-24 w-24 sm:w-28 sm:h-28 lg:w-32 lg:h-32 bg-blue-royal-azure flex items-center justify-center font-semibold;
}

.change-pwd-button {
  @apply bg-gray-charcoal rounded-xl p-8 w-full md:w-[80%] lg:w-[50%] flex flex-col items-center justify-center mt-7 md:mt-5 text-white  hover:bg-black transition-all duration-300;
}
